<template lang="pug">
.reseller-templates(v-if="this.isSub && getPossibleTemplates().length")
  .reseller-templates-row
    CollectionPreviews.reseller-templates-row__block(
      forcedShowLink
      :showTitle="false"
      :name="name"
      :to="seeAllRoute"
      :templates="resellerTemplates"
    )
</template>

<script>
  import { mapState } from 'vuex';
  import CollectionPreviews from '../CollectionPreviews.vue';
  import filterHelperMixin from '../../mixins/filterHelper';

  export default {
    name: 'ResellerTemplates',
    components: {
      CollectionPreviews,
    },
    mixins: [filterHelperMixin],
    computed: {
      ...mapState(['accountType', 'whiteLabelSettings']),
      ...mapState('templateChooser', ['resellerTemplates']),
      isSub() {
        return this.accountType === 'sub';
      },
      seeAllRoute() {
        return {
          name: 'reseller-templates',
          query: this.$route.query,
        };
      },
      brandName() {
        const brand = this.whiteLabelSettings?.brandName;

        return !brand || brand === 'OptiMonk'
          ? this.$t('templateManager.tabs.partnerFeatured')
          : brand;
      },
      name() {
        return this.$t('resellerTemplates', { brand: this.brandName });
      },
    },

    methods: {
      getPossibleTemplates() {
        return this.resellerTemplates || [];
      },
    },
  };
</script>

<style lang="sass">
  .reseller-templates-row__block
    margin-bottom: 3.75rem
</style>
